import React from 'react';
import { Modal } from 'react-bootstrap';
import LoaderButton from '../LoaderButton/LoaderButton.js';
import './ConfirmModal.css';

const ConfirmModal = props => {
    const handleConfirm = () => {
        props.confirmModal();
        props.closeConfirmModal();
    };

    const handleClose = () => {
        props.closeConfirmModal();
    };

  return (
    <div>
      <Modal
        show={props.showConfirmModal}
        onHide={props.closeConfirmModal}
        dialogClassName="ConfirmModal"
        aria-labelledby="Confirm Modal"
      >
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Body className="text-center">
          {props.message}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <LoaderButton
              type="submit"
              isLoading={false}
              text="OK"
              loadingText=" Submitting..."
              onClick={handleConfirm}
            />{' '}
            <LoaderButton
              type="submit"
              isLoading={false}
              text="Cancel"
              loadingText=" Cancelling..."
              onClick={handleClose}
            />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
