import React, { useEffect, useRef, useState } from 'react';
import bsCustomFileInput from 'bs-custom-file-input';
import Papa from 'papaparse';
import { Modal } from 'react-bootstrap';
import LoaderButton from '../LoaderButton/LoaderButton';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './BulkAddOrgModal.css';

const BulkAddOrgModal = (props) => {
  const { closeModal, showBulkAddOrgModal, dispatch } = props;
  const [csvfile, setCsvfile] = useState(undefined);
  const [resultData, setResultData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const existingOrgNames = props.organizations.map((org) => org.orgName);
  const knownSpecTypes = ['PO', 'NONPO', 'NONPO-RealTime'];
  const isMountedRef = useRef(null);
  const idToken = useSelector((state) => state.tokens.idToken);

  const importCSV = () => {
    Papa.parse(csvfile, {
      complete: updateData,
      header: true,
      skipEmptyLines: 'greedy',
    });
  };

  const checkSpecificationTypes = (specificationType) => {
    return knownSpecTypes.includes(specificationType);
  };

  const checkDupes = (orgName) => {
    return existingOrgNames.includes(orgName);
  };

  const handleChange = (event) => {
    setCsvfile(event.target.files[0]);
  };

  const validationCheck = () => {
    return csvfile !== undefined;
  };

  const updateData = (result) => {
    let data = result.data;
    setResultData(data);
  };

  const addOrgs = async (item) => {
    if (!checkDupes(item.Organization) && checkSpecificationTypes(item.SpecificationType)) {
      const params = {
        ...(item.Organization !== '' ? { orgName: item.Organization.replace(/\s/g, '') } : {}),
        ...(item.Population !== '' ? { orgPopulation: item.Population.replace(/\s/g, '') } : {}),
        ...(item.SpecificationType !== '' ? { specificationType: item.SpecificationType } : {}),
        ...(item.OrgType !== '' ? { orgType: item.OrgType } : {}),
        ...(item.ExpireACRS !== '' ? { expireACRS: item.ExpireACRS } : {}),
        ...(item.ExpirationDays !== '' ? { expirationDays: item.ExpirationDays } : {})
      };
      try {
        const organizations_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/organization`;
        const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
        await axios.put(organizations_url, params, options)
        .then((res) => {

        })
        .catch((err) => {
          console.error(err);
          
        });

      } catch (err) {
        console.error(err, err.stack);
        alert('There was a problem with your upload. Please check to make sure that you are uploading a csv file with the proper headers.');
      }
    } else if (checkDupes(item.Organization)) {
      dispatch({
        type: 'ADD_DUPLICATE_ORGS',
        duplicateOrgs: item.Organization,
        errorMessage: 'The following organizations already exist and have not been added',
        showErrorModal: true,
        errorType: 'dupe',
      });
    } else if (!checkSpecificationTypes(item.SpecificationType)) {
      dispatch({
        type: 'ADD_INVALID_ORG_TYPE',
        invalidOrgTypes: item.SpecificationType,
        errorMessage: 'The following organization types are not valid and the associated organizations were not loaded.',
        showErrorModal: true,
        errorType: 'invalidOrgs',
      });
    }
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
    setCsvfile(undefined);
  };

  useEffect(() => {
    isMountedRef.current = true;
    bsCustomFileInput.init();
    return () => (isMountedRef.current = false);
  });

  useEffect(() => {
    isMountedRef.current = true;

    if (resultData) {
      setIsLoading(true);

      const processData = async () => {
        await Promise.all(resultData.map(addOrgs));
        props.handleRefresh();
      }
      processData();

      setIsLoading(false);
      setCsvfile(undefined);      
    }
    return () => (isMountedRef.current = false);
  }, [resultData]);

  return (
    <div>
      <Modal
        show={showBulkAddOrgModal}
        onHide={closeModal}
        dialogClassName="BulkAddOrgModal"
        aria-labelledby="Add Organization Modal"
      >
        <Modal.Header closeButton>
          <div className="icon-box">
            <i className="material-icons">add_circle_outline</i>
          </div>
          <h4 className="modal-title">Organization Bulk Upload</h4>
        </Modal.Header>

        <Modal.Body>
          <p>Please upload only .csv files that have the following headers:</p>
          <ul>
            <li>Organization</li>
            <li>Population</li>
            <li>SpecificationType</li>
            <li>OrgType</li>
            <li>ExpireACRS</li>
            <li>ExpirationDays</li>
          </ul>
          <p>
            Note: If there are spaces in the Organization and/or Population
            fields they will be removed.
          </p>

          <div className="custom-file">
            <input
              id="BulkAddOrgModalInput"
              type="file"
              className="custom-file-input"
              onChange={handleChange}
            />
            <label className="custom-file-label" htmlFor="BulkAddOrgModalInput">
              Choose file
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <LoaderButton
              disabled={!validationCheck()}
              type="submit"
              isLoading={isLoading}
              text="Add Organizations"
              loadingText=" Adding Organizations..."
              onClick={importCSV}
            />
            <LoaderButton
              type="submit"
              isLoading={isLoading}
              text="Cancel"
              loadingText=" Cancelling..."
              variant="outline-secondary"
              onClick={handleCancel}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BulkAddOrgModal;
