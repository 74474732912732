import React, { useState } from 'react';
import triggerStepFunction from '../../helpers/triggerStepFunction';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import LoaderButton from '../LoaderButton/LoaderButton.js';
import './PartialModal.css';

const PartialModal = props => {
  const [isCancelling, setIsCancelling] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const idToken = useSelector((state) => state.tokens.idToken);

  const handlePartialSubmit = async () => {
    let filesubmissionId = props.submission.id;
    let action = 'partial';

    setIsSubmitting(true);
    let sf_promise = triggerStepFunction(filesubmissionId, action, idToken);
    await sf_promise.then((response) => {
      if (!response) {
        console.log('Partial load not submitted successfully!')
      } else {
        console.log('Partial load submitted successfully!')
      }
    });
    props.closePartialModal();
    setIsSubmitting(false);
  };

  const handleClose = () => {
    setIsCancelling(true);
    props.closePartialModal();
    setIsCancelling(false);
  };

  return (
    <div>
      <Modal
        show={props.showPartialModal}
        onHide={props.closePartialModal}
        dialogClassName="PartialModal"
        aria-labelledby="Partial Modal"
      >
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Body className="text-center">
            <p>
              By clicking submit you are agreeing to remove all invalid rows
              from your submission. This may result in messages not being
              delivered for the patients in rows that were removed until those
              rows have been corrected and the file has been resubmitted.
            </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <LoaderButton
              type="submit"
              isLoading={isSubmitting}
              text="Yes"
              loadingText=" Submitting..."
              onClick={handlePartialSubmit}
            />{' '}
            <LoaderButton
              type="submit"
              isLoading={isCancelling}
              text="Cancel"
              loadingText=" Cancelling..."
              onClick={handleClose}
            />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PartialModal;
