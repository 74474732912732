import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import Switch from '../Switch/Switch';
import LoaderButton from '../LoaderButton/LoaderButton';
import { useSelector } from 'react-redux';
import './AddOrgModal.css';
import axios from 'axios';

const AddOrgModal = ({ showAddOrgModal, closeModal, dispatch, handleRefresh, organizations}) => {
  const [orgName, setOrgName] = useState('');
  const [orgPopulation, setOrgPopulation] = useState('');
  const [orgType, setOrgType] = useState('');
  const [specificationType, setSpecificationType] = useState('');
  const [independentFileLoad, setIndependentFileLoad] = useState(false);
  const [expireACRS, setExpireACRS] = useState(true);
  const [expirationDays, setExpirationDays] = useState('90');
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const idToken = useSelector((state) => state.tokens.idToken);
  const customerDisabledProps = (process.env.REACT_APP_FILESUBMISSION_DOMAIN.includes("njhin.services") || process.env.REACT_APP_FILESUBMISSION_DOMAIN.includes("mihin.cloud"));
  const switchColor = customerDisabledProps ? "#e9ecef" : "#06D6A0";
  

  function checkDupes(orgName) {
    return organizations.map((org) => org.orgName).includes(orgName)
  }


  const validateForm = () => {
    return orgName && orgPopulation && specificationType;
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const handleOrgChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      setOrgName(e.currentTarget.value.replace(/\s/g, ''));
    } else {
      setOrgName(e.currentTarget.value);
    }
  };

  const handlePopChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      setOrgPopulation(e.currentTarget.value.replace(/\s/g, ''));
    } else {
      setOrgPopulation(e.currentTarget.value);
    }
  };

  const handleAddOrg = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (checkDupes(orgName)) {
      dispatch({
        type: 'ADD_DUPLICATE_ORGS',
        duplicateOrgs: orgName,
        errorMessage:
          'The following organization already exists and has not been added',
        showErrorModal: true,
        errorType: 'dupe',
      });
      setIsLoading(false);
    }
    else {  
      const inputData = {
        orgName: orgName,
        orgPopulation: orgPopulation,
        orgType: orgType,
        specificationType: specificationType,
        expireACRS: expireACRS,
        independentFileLoad: independentFileLoad,
        expirationDays: expirationDays.trim() === '' ? '90' : expirationDays,
      };

      try {
        const organizations_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/organization`;
        const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
        await axios.put(organizations_url, inputData, options)
        .then((res) => {

        })
        .catch((err) => {
          console.error(err);
        });

      } catch (err) {
        console.error(err, err.stack);
      }
      setOrgName('');
      setOrgPopulation('');
      setOrgType('');
      setSpecificationType('');
      setIndependentFileLoad(false)
      setExpireACRS(true);
      setExpirationDays('90')
      setIsLoading(false);
      closeModal();
      handleRefresh();
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsCancelling(true);
    setOrgName('');
    setOrgPopulation('');
    setOrgType('');
    setSpecificationType('');
    setIndependentFileLoad(false)
    setExpireACRS(true);
    setExpirationDays('90')
    closeModal();
    setIsCancelling(false);
  };

  return (
    <div>
      <Modal
        show={showAddOrgModal}
        onHide={closeModal}
        dialogClassName="AddOrgModal"
        aria-labelledby="Add Organization Modal"
      >
        <Modal.Header closeButton>
          <div className="icon-box">
            <i className="material-icons">add_circle_outline</i>
          </div>
          <h4 className="modal-title">Add Organization</h4>
        </Modal.Header>

        <Modal.Body>
          <div className="mt-4">
            <Form.Group controlId="orgName">
              <Form.Label className="orgModalLabel">
                Organization Name: <span className="required">(Required)</span>
              </Form.Label>

              <Form.Control
                required
                type="text"
                value={orgName}
                onKeyDown={handleKeyDown}
                onChange={handleOrgChange}
              />
              <Form.Text className="text-muted">
                Spaces are not allowed in Organization names.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="orgPop">
              <Form.Label className="orgModalLabel">
                Population: <span className="required">(Required)</span>
              </Form.Label>

              <Form.Control
                required
                type="text"
                value={orgPopulation}
                onKeyDown={handleKeyDown}
                onChange={handlePopChange}
              />
              <Form.Text className="text-muted">
                Spaces are not allowed in Population names.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="specificationType">
              <Form.Label className="orgModalLabel">
                Specification Type: <span className="required">(Required)</span>
              </Form.Label>
              
              <Form.Control
                as="select"
                value={specificationType}
                onChange={(e) => {
                  setSpecificationType(e.target.value);
                }}
              >
                <option></option>
                <option>PO</option>
                <option>NONPO</option>
                <option>NONPO-RealTime</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="orgType">
              <Form.Label className="orgModalLabel">
                Organization Type:
              </Form.Label>

              <Form.Control
                as="select"
                value={orgType}
                onChange={(e) => {
                  setOrgType(e.target.value);
                }}
              >
                <option></option>
                <option>Health Information Exchange</option>
                <option>Health System</option>
                <option>Payer</option>
                <option>Hospital</option>
                <option>Clinic</option>
                <option>Other</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="independentFileLoad">
              <Row>
                <Col>
                  <Form.Label className="orgModalLabel">Load Files Independently?</Form.Label>
                </Col>
                <Col>
                  <Switch
                      isOn={independentFileLoad}
                      disabled={customerDisabledProps}
                      onColor={switchColor}
                      handleToggle={() => setIndependentFileLoad(!independentFileLoad)}
                      switchId="independentFileLoad"
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group controlId="expireACRS">
              <Row>
                <Col>
                  <Form.Label className="orgModalLabel">Expire Existing ACRS?</Form.Label>
                </Col>
                <Col>
                  <Switch
                      isOn={expireACRS}
                      disabled={customerDisabledProps}
                      onColor={switchColor}
                      handleToggle={() => setExpireACRS(!expireACRS)}
                      switchId="expireACRS"
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group controlId="expirationDays">
              <Form.Label className="orgModalLabel">Expiration Days:</Form.Label>
              <Form.Control
                  type="text"
                  value={expirationDays}
                  disabled={customerDisabledProps}
                  onChange={(e) => setExpirationDays(e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <LoaderButton
              type="submit"
              isLoading={isLoading}
              text="Add"
              loadingText=" Adding provider..."
              onClick={handleAddOrg}
              disabled={!validateForm()}
            />{' '}
            <LoaderButton
              type="submit"
              isLoading={isCancelling}
              text="Cancel"
              loadingText=" Cancelling adding provider..."
              variant="outline-secondary"
              onClick={handleCancel}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddOrgModal;
