import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './ConfirmationModal.css';

const ConfirmationModal = (props) => {
  const {
    showModal,
    hideModal,
    action,
    item,
    type,
    handleConfirm,
    handleCancel,
    isCancelling,
    isConfirming,
    confirmBtnColor,
    cancelBtnColor,
    confirmBtnBgColor,
    cancelBtnBgColor,
  } = props;
  const [confirmText, setConfirmText] = useState('');
  const [cancelText, setCancelText] = useState('');

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    switch (action.toLowerCase()) {
      case 'delete':
        setConfirmText(` deleting ${type}`);
        break;
      case 'update':
        setConfirmText(` updating ${type}`);
        break;
      case 'change':
        setConfirmText(` changing ${type}`);
        break;
      default:
        throw new Error('Action not found');
    }
    setCancelText(` cancelling ${action}`);
    return () => (isMountedRef.current = false);
  }, [action, type]);

  return (
    <div>
      <Modal
        show={showModal}
        onHide={hideModal}
        dialogClassName="ConfirmationModal"
        aria-labelledby="Confirmation Modal"
        dismissable="true"
      >
        <Modal.Header closeButton>
          <div className="icon-box">
            <i className="material-icons">pan_tool</i>
          </div>
          <h4 className="modal-title">Are you sure?</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>
              {`Do you really want to ${action.toLowerCase()} ${item}? This cannot be undone.`}
            </p>
            <div className="clearfix">
              <button
                type="button"
                className="btn cancelBtn"
                onClick={handleCancel}
                style={{
                  backgroundColor: `${cancelBtnBgColor}`,
                  color: `${cancelBtnColor}`,
                }}
              >
                {isCancelling ? `${cancelText}` : `Cancel`}
              </button>
              <button
                type="button"
                className="btn confirmBtn"
                onClick={handleConfirm}
                style={{
                  backgroundColor: `${confirmBtnBgColor}`,
                  color: `${confirmBtnColor}`,
                }}
              >
                {isConfirming ? `${confirmText}` : `${action}`}
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
