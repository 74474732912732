import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const initialState = { organizations: [] };


function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { organizations: action.payload };
    case 'add':
      return { organizations: [...state.organizations, action.payload] };
    case 'clear':
      return { organizations: [] };
    default:
      throw new Error('Action not found');
  }
}

function sortOrgs(a, b) {
  if (a.orgName < b.orgName) { return -1;}
  else if (b.orgName < a.orgName) { return 1;}
  else { return 0;}
}

function useOrgs() {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const isMountedRef = useRef(null);
  const idToken = useSelector((state) => state.tokens.idToken);

  const clearOrganizations = () => {
    dispatch({ type: 'clear' });
  };
  
  const getOrganizations = async () => {
    try {
      let allItems = [];
      const organizations_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/organizations`;
      const headers = {headers: { 'Authorization': 'Bearer ' + idToken }};
      await axios.get(organizations_url, headers)
      .then((res) => {
        allItems = res.data.data.listOrganizationAdmins.items.sort(sortOrgs);
      })
      .catch((err) => {
        console.error(err);
      });

      dispatch({ type: 'set', payload: allItems });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  return [state.organizations, getOrganizations, clearOrganizations];
}

export default useOrgs;
