import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { configureStore  } from '@reduxjs/toolkit'; 
import rootReducer from './state/reducers/index.js';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';

const store = configureStore({ reducer: rootReducer });
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);
