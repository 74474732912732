import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import './Header.css';

const Header = () => {
  const { user, handleSignout } = useContext(AuthContext);

  return (
    <>
      <div className="signout">
        {user && <Button onClick={handleSignout}>Signout</Button>}
      </div>
    </>
  );
};

export default Header;
