import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import Switch from '../Switch/Switch';
import LoaderButton from '../LoaderButton/LoaderButton';
import './AddOrgModal.css';
import { useSelector } from 'react-redux';
import axios from 'axios';

const EditOrgModal = (props) => {
  const [, setOrgName] = useState('');
  const [orgPopulation, setOrgPopulation] = useState('');
  const [orgType, setOrgType] = useState('');
  const [specificationType, setSpecificationType] = useState('');
  const [independentFileLoad, setIndependentFileLoad] = useState(false);
  const [expireACRS, setExpireACRS] = useState(false);
  const [expirationDays, setExpirationDays] = useState('90');
  const [isLoading, setIsLoading] = useState(false);
  const idToken = useSelector((state) => state.tokens.idToken);
  const customerDisabledProps = (process.env.REACT_APP_FILESUBMISSION_DOMAIN.includes("njhin.services") ||process.env.REACT_APP_FILESUBMISSION_DOMAIN.includes("mihin.cloud"));
  const switchColor = customerDisabledProps ? "#e9ecef" : "#06D6A0";

  useEffect(() => {
    setOrgName(props.orgToEditName);
    setOrgPopulation(props.orgToEditPopulation);
    setOrgType(props.orgToEditType);
    setSpecificationType(props.specificationType);
    setIndependentFileLoad(props.independentFileLoad)
    setExpireACRS(props.expireACRS);
    setExpirationDays(props.expirationDays);
  }, [props.showEditOrgModal, props.orgToEditName, props.orgToEditPopulation, props.orgToEditType, props.specificationType, props.expireACRS, props.expirationDays, props.independentFileLoad]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const inputData = {
      orgName: props.orgToEdit,
      orgPopulation: orgPopulation,
      orgType: orgType,
      specificationType: specificationType,
      expireACRS: expireACRS,
      independentFileLoad: independentFileLoad,
      expirationDays: String(expirationDays).trim() === '' ? '90' : expirationDays,
    };

    try {
      const organizations_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/organization`;
      const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
      await axios.put(organizations_url, inputData, options)
      .then((res) => {

      })
      .catch((err) => {
        console.error(err);
      });
    } catch (err) {
      console.error(err, err.stack);
    }

    props.closeModal();
    setIsLoading(false);
    props.handleRefresh();
  };

  return (
    <div>
      <Modal
        show={props.showEditOrgModal}
        onHide={props.closeModal}
        dialogClassName="modal-40w"
        aria-labelledby="Edit Organization Modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>{props.orgToEditName}</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form className="mt-4" onSubmit={handleUpdate}>
            <Form.Group controlId="orgPop">
              <Form.Label className="orgModalLabel">
                Population: <span className="required">(Required)</span>
              </Form.Label>

              <Form.Control
                required
                type="text"
                value={orgPopulation}
                onChange={(e) => {
                  setOrgPopulation(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="specificationType">
              <Form.Label className="orgModalLabel">
                Specification Type: <span className="required">(Required)</span>
              </Form.Label>
              
              <Form.Control
                as="select"
                value={specificationType}
                onChange={(e) => {
                  setSpecificationType(e.target.value);
                }}
              >
                <option></option>
                <option>PO</option>
                <option>NONPO</option>
                <option>NONPO-RealTime</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="orgType">
              <Form.Label className="orgModalLabel">
                Organization Type:
              </Form.Label>

              <Form.Control
                as="select"
                value={orgType}
                onChange={(e) => {
                  setOrgType(e.target.value);
                }}
              >
                <option></option>
                <option>Health Information Exchange</option>
                <option>Health System</option>
                <option>Payer</option>
                <option>Hospital</option>
                <option>Clinic</option>
                <option>Other</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="independentFileLoad">
              <Row>
                <Col>
                  <Form.Label className="orgModalLabel">Load Files Independently?</Form.Label>
                </Col>
                <Col>
                  <Switch
                      isOn={independentFileLoad}
                      disabled={customerDisabledProps}
                      onColor={switchColor}
                      handleToggle={() => setIndependentFileLoad(!independentFileLoad)}
                      switchId="independentFileLoad"
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group controlId="expireACRS">
              <Row>
                <Col>
                  <Form.Label className="orgModalLabel">Expire Existing ACRS?</Form.Label>
                </Col>
                <Col>
                  <Switch
                      isOn={expireACRS}
                      disabled={customerDisabledProps}
                      onColor={switchColor}
                      handleToggle={() => setExpireACRS(!expireACRS)}
                      switchId="expireACRS"
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group controlId="expirationDays">
              <Form.Label className="orgModalLabel">Expiration Days:</Form.Label>
              <Form.Control
                  type="text"
                  value={expirationDays}
                  disabled={customerDisabledProps}
                  onChange={(e) => setExpirationDays(e.target.value)}
              />
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              isLoading={isLoading}
              text="Update Organization"
              loadingText=" Updating organization..."
            />
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditOrgModal;
