const updateFileProgress = (obj) => {
  return {
    type: 'UPDATE_FILE_PROGRESS',
    payload: obj,
  };
};

const exportedObject = {
  updateFileProgress,
};

export default exportedObject;