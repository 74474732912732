import axios from 'axios';

const triggerStepFunction = async (filesubmissionId, action, idToken) => {

  const submitData = {
    id: filesubmissionId,
    action: action
  };

  const start_execution_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/startexecution`;
  const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
  return axios.post(start_execution_url, submitData, options)
    .then((res) => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

export default triggerStepFunction;
