import axios from 'axios';

const uploadFile = async (
  file,
  signedS3Url,
  setProgress
) => {
  return new Promise(async (resolve) => {
    const headers = {};

    await axios.request({
      method: 'put',
      url: signedS3Url,
      headers: headers,
      data: file,
      onUploadProgress: progressEvent => {
        if (progressEvent.event.lengthComputable) {
          setProgress(progressEvent.progress * 100);
        }
      }
    })
    .then((res) => {

    })
    .catch((err) => {
      console.error(err);
    });

    resolve('done');
  });
};

export default uploadFile;
