import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { AuthProvider } from './context/AuthContext';
import './App.css';
import Home from './components/Home/Home';
import allActions from './state/actions';


const App = () => {
  window.addEventListener('message', receiveMessage, false);
  const isMountedRef = useRef(null);
  const [idToken, setIdToken] = useState('');
  const [extAccessToken, setExtAccessToken] = useState('');
  const [extIdToken, setExtIdToken] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [verified, setVerified] = useState(null);
  const [extUser] = useState({'attributes': ''});
  const dispatch = useDispatch();

  const handleSignout = async () => {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error('Error signing out user ', error);
    }
  };

  function receiveMessage(event) {
    if (!event.data || !event.origin) {
      return;
    }

    let eventData = JSON.parse(event.data);

    let externalAccessToken = eventData['access_token'];
    // let externalUser = eventData['user'];
    let externalIdToken = eventData['id_token'];
    setExtAccessToken(externalAccessToken);
    setExtIdToken(externalIdToken);
    dispatch(allActions.tokenActions.setAccessToken(externalAccessToken));
    dispatch(allActions.tokenActions.setIdToken(externalIdToken));
    setIdToken(externalIdToken);
  }

  useEffect(() => {
    isMountedRef.current = true;
    
    //for testing locally
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
      console.log(process.env.NODE_ENV + ' env detected! Setting access and id tokens.')
      setExtAccessToken(process.env.REACT_APP_COGNITO_ACCESS_TOKEN);
      setExtIdToken(process.env.REACT_APP_COGNITO_ID_TOKEN);
      dispatch(allActions.tokenActions.setAccessToken(process.env.REACT_APP_COGNITO_ACCESS_TOKEN));
      dispatch(allActions.tokenActions.setIdToken(process.env.REACT_APP_COGNITO_ID_TOKEN));
      setIdToken(process.env.REACT_APP_COGNITO_ID_TOKEN);
    }

    return () => (isMountedRef.current = false);
  }, [dispatch]);

  useEffect(() => {
    isMountedRef.current = true;
    if (extAccessToken !== '') {
      const healthCheckUrl = 'https://' + process.env.REACT_APP_FILESUBMISSION_DOMAIN + '/healthcheck'
      const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
      const validateAccessToken = () => {
        axios.post(healthCheckUrl, {}, options)
          .then((res) => {
            setVerified(true);
          })
          .catch((err) => {
            console.error(err);
            setVerified(false);
          });
      };
      validateAccessToken();
    }

    return () => (isMountedRef.current = false);
    // Only add accessTokenPayload to dependency array to avoid multiple calls to API gateway/Lambda
  }, [idToken, extAccessToken]);

  function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  useEffect(() => {
    isMountedRef.current = true;
    if (extIdToken) {
      if (isMountedRef.current) {
        extUser.attributes = parseJwt(extIdToken);
        if (extUser.attributes['custom:role'] === 'admin') {
          setIsAdmin(true);
        }
        if (extUser.attributes['custom:role'] === 'super admin') {
          setIsSuperAdmin(true);
        }
      }
    }
    return () => (isMountedRef.current = false);
  }, [extIdToken, extUser]);

  return (
      <AuthProvider
        value={{
          handleSignout,
          isAdmin,
          isSuperAdmin,
          idToken,
          extUser,
        }}
      >
        {verified === true && <Home />}
      </AuthProvider>
  );
};

export default App;
