import React, { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Button, Card, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import PartialModal from '../PartialModal/PartialModal';
import './StatusModal.css';

const StatusModal = (props) => {
  const [showPartialModal, setShowPartialModal] = useState(false);

  const closePartialModal = () => {
    setShowPartialModal(false);
  };

  let partialValidationErrors = 0;
  let crossValidationErrors = 0;

  if (props.partialMessage && props.partialMessage.length > 0) {
    partialValidationErrors = props.partialMessage[0].error.errorMessages.length;
  }
  if (props.cvMessage && props.cvMessage.length > 0) {
    crossValidationErrors = props.cvMessage[0].cross.rows.length;
  }

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        dialogClassName="StatusModal"
        aria-labelledby="Edit User Modal"
      >
        {props.partialMessage.length === 0 && props.cvMessage.length === 0 ? (
          <div
            style={{
              width: '100%',
              height: 80,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ThreeDots color="#223c89" height="80" width="80" />
          </div>
        ) : (
          <>
            <Modal.Header closeButton>
              {/* <div className="icon-box">
                <i className="material-icons">error_outline</i>
              </div> */}
              <Modal.Title>
                <h4>Validation Errors</h4>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <>
                {props.partialMessage !== undefined &&
                  props.partialMessage.length !== 0 &&
                  props.partialMessage.length !== undefined && (
                    <div>
                      {props.partialMessage.map((item, index) => (
                        <div key={index}>
                          <h5 className="mt-3" key={index}>
                            {item.error.filetype} File Errors
                          </h5>
                          {item.error.errorMessages.map((item2, index2) => (
                            <Card key={index + index2}>
                              <ListGroup className="list-group-flush">
                                <ListGroupItem>
                                  <div>
                                    <strong>Message:</strong> {item2.message}
                                    {item2.messageList !== undefined && (
                                    <div>
                                      {item2.messageList.map((item2Message, index3) => (
                                        <div><br />{item2Message}</div>
                                      ))}
                                    </div>
                                    )}
                                  </div>
                                  {item2.row !== undefined && (
                                    <div>
                                    <div>
                                      <strong>Columns:</strong> {item2.column}
                                    </div>
                                    <div>
                                      <strong>
                                        {item2.row.length > 2 ? 'Row Numbers:' : 'Row Number:'}
                                      </strong>{' '}
                                      {item2.row.join(', ')}
                                    </div>
                                    </div>
                                  )}
                                  </ListGroupItem>
                                </ListGroup>
                            </Card>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                {props.cvMessage.length !== 0 && (
                  <>
                    <h5 className="mt-3">Cross Validation Errors:</h5>
                    {props.cvMessage.map((item, index) => (
                      <div key={index}>
                        <div className="errorMessage">
                          <h6>Error Message: </h6>
                          <p>{item.cross.errorMessage}</p>
                        </div>
                        {item.cross.rows.slice(0, 200).map((item2, index2) => (
                          <Card key={index + index2}>
                              <ListGroup className="list-group-flush">
                                <ListGroupItem key={item2.Provider_NPI}>
                                  <div>
                                    <strong>Row Number:</strong> {item2.row}
                                  </div>
                                  <div>
                                    <strong>NPI:</strong> {item2.Provider_NPI}
                                  </div>
                                  <div>
                                    <strong>Practice:</strong>{' '}
                                    {item2.Practice_Name}
                                  </div>
                                  <div>
                                    <strong>Organization:</strong>{' '}
                                    {item2.Managing_Organization_Name}
                                  </div>
                                </ListGroupItem>
                              </ListGroup>
                              </Card>
                          ))}
                      </div>
                    ))}
                  </>
                )}
              </>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-center">            
                  {(crossValidationErrors > 200 || partialValidationErrors > 200) ? (
                    <p>Only 200 of {crossValidationErrors + partialValidationErrors} errors displayed.</p>
                  ) : (<p>Displaying {crossValidationErrors + partialValidationErrors} of {crossValidationErrors + partialValidationErrors} errors.</p>)}

                {(props.submission.status === 'Loaded' && props.submission.description != null && props.submission.description.toUpperCase().includes('SFTP')) ? (
                  <p>Files were submitted via SFTP and valid rows were loaded. Please resubmit files to fix any invalid rows or cross validation errors.</p>
                  ) : (props.submission.status === 'Incorrect File') ? (
                    <p>Incorrect file format was detected. Please fix the invalid formatting and resubmit files.</p>
                  ) : (props.submission.status === 'Partial Loading') ? (
                  <p>Files were submitted for partial loading and the valid rows are currently being loaded.</p>
                  ) : (props.submission.status === 'Loaded' && (!props.submission.attValid || !props.submission.deliveryValid || !props.submission.crossValid)) ? (
                    <p>Files were submitted for partial loading and have finished loading. Please resubmit files to fix any invalid rows or cross validation errors.</p>
                  ) : (props.submission.originalAction !== 'validate') ? (
                  <span>
                  <p>
                    If you accept these errors and want to continue anyway, click
                    on 'Submit'. Otherwise click 'Cancel'.
                  </p>
                  <Button
                    onClick={() => {
                      setShowPartialModal(true);
                      props.closeModal();
                    }}>
                    Submit
                  </Button>{' '}
                  </span>
                ) : (
                  <p>Files were submitted for validation only. Please resubmit files to validate again.</p>
                )}

                <Button onClick={props.closeModal}>Cancel</Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>

      <PartialModal
        submission={props.submission}
        showPartialModal={showPartialModal}
        closePartialModal={closePartialModal}
      />
    </>
  );
};

export default StatusModal;
