import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useSelector } from 'react-redux';
import axios from 'axios';

const initialState = { submissions: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { submissions: action.payload };
    case 'add':
      return { submissions: [...state.submissions, action.payload] };
    default:
      throw new Error('Action not found');
  }
}

function useQuery(showCurrentUserOnly, orgName, setTableLoading, setShowTable) {
  const { user, extUser } = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMountedRef = useRef(null);
  const [email, setEmail] = useState('');
  const idToken = useSelector((state) => state.tokens.idToken);

  const getFileSubmissions = async () => {
    try {
      let allItems = [];
      if (user) {
        setEmail(user.attributes.email);
      } else {
        setEmail(extUser.attributes.email);
      }

      let paramUserName = "";
      if (showCurrentUserOnly && email !== '') {
        paramUserName = `email=${email}`;
      }

      let paramOrgName = "";
      if (orgName != null && orgName !== "null" && orgName !== "" && orgName !== "Choose...") {
        paramOrgName = `orgName=${orgName}`;
      }

      if (paramOrgName !== "" || paramUserName !== "") {
        setTableLoading(true);
        setShowTable(false);
        const submissions_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/submissions?${paramOrgName}&${paramUserName}`;
        const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
        await axios.get(submissions_url, options)
        .then((res) => {
          allItems = res.data.data.listFileSubmissions.items;
        })
        .catch((err) => {
          console.error(err);
        });
        setTableLoading(false);
        setShowTable(true);
      } else {
        setTableLoading(false);
        setShowTable(false);
      }

      dispatch({ type: 'set', payload: allItems });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    const getFileSubmissions = async () => {
      try {
        let allItems = [];
        if (user) {
          setEmail(user.attributes.email);
        } else {
          setEmail(extUser.attributes.email);
        }
        
        let paramUserName = "";
        if (showCurrentUserOnly && email !== '') {
          paramUserName = `email=${email}`;
        }

        let paramOrgName = "";
        if (orgName != null && orgName !== "null" && orgName !== "" && orgName !== "Choose...") {
          paramOrgName = `orgName=${orgName}`;
        }

        if (paramOrgName !== "" || paramUserName !== "") {
          setTableLoading(true);
          setShowTable(false);
          const submissions_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/submissions?${paramOrgName}&${paramUserName}`;
          const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
          await axios.get(submissions_url, options)
          .then((res) => {
            allItems = res.data.data.listFileSubmissions.items;
          })
          .catch((err) => {
            console.error(err);
          });
          setTableLoading(false);
          setShowTable(true);
        } else {
          setTableLoading(false);
          setShowTable(false);
        }

        dispatch({ type: 'set', payload: allItems });
      } catch (e) {
        console.error(e);
      }
    };
    getFileSubmissions();
    return () => (isMountedRef.current = false);
  }, [email, user, extUser, orgName, showCurrentUserOnly, idToken, setTableLoading, setShowTable]);

  return [state.submissions, getFileSubmissions];
}

export default useQuery;
