import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import LoaderButton from '../LoaderButton/LoaderButton';
import './OrgModals.css';

const DupeOrgsModal = (props) => {
  const { dupeOrgs, closeModal, showDupeOrgsModal } = props;
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    closeModal();
    setIsClosing(false);
  };
  return (
    <div>
      <Modal
        show={showDupeOrgsModal}
        onHide={closeModal}
        dialogClassName="modal-20w"
        aria-labelledby="Duplicate Organizations Modal"
      >
        <Modal.Body className="text-center">
          <>
            <h5>
              The following organizations already exist. Organization Name must be unique.
            </h5>
            <br />
            {dupeOrgs.map((org, index) => (
              <p key={`${org}-${index}`}>{org}</p>
            ))}

            <LoaderButton
              type="submit"
              isLoading={isClosing}
              text="Close"
              loadingText=" Closing..."
              onClick={handleClose}
            />
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DupeOrgsModal;
