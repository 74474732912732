import triggerStepFunction from './triggerStepFunction';
import axios from 'axios';

const createFileSub = async (
  popName,
  transactionId,
  attFileName,
  attFilePath,
  deliveryFileName,
  deliveryFilePath,
  orgName,
  orgType,
  specificationType,
  userName,
  description,
  validate,
  setShowError,
  setErrorMessage,
  expireACRS,
  expirationDays,
  submissionTime,
  frontSubmissionTime,
  idToken,
  skipAttribution
) => {
  const action = validate ? 'validate' : 'load';
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  const inputData = {
    popName: popName,
    transactionId: transactionId,
    submissionTime: submissionTime,
    frontSubmissionTime: frontSubmissionTime,
    attFileName: attFileName,
    attFilePath: attFilePath,
    deliveryFileName: deliveryFileName,
    deliveryFilePath: deliveryFilePath,
    status: 'submitted',
    statusMessage: 'File has been submitted for processing...',
    orgName: orgName.replace(/\s+/g, ''),
    orgType: orgType,
    specificationType: specificationType,
    description: description ? description : 'No description',
    action: action,
    expireACRS: expireACRS,
    expirationDays: expirationDays,
    skipAttribution: skipAttribution.toString()
  };

  const put_filesubmission_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/submission`;
  const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
  let filesubmissionId = null;
  let sf_promise = null;
  await axios.put(put_filesubmission_url, inputData, options)
    .then((res) => {
      filesubmissionId = res.data.id;
      console.log(`Filesubmission Id ${filesubmissionId}`);
      sf_promise = triggerStepFunction(filesubmissionId, action, idToken);
    }).catch((err) => {
      console.error(err, err.stack);
      setShowError(true);
      setErrorMessage(`There was an error with your upload for ${inputData.orgName}. Please try again. If the problem persists please contact ${supportEmail}`);
    });

    await sf_promise.then((response) => {
      if (!response) {
        setShowError(true);
        setErrorMessage(`There was an error with your upload for ${inputData.orgName}. Please try again. If the problem persists please contact ${supportEmail}`);
      }
    });
};

export default createFileSub;
