import React, { useContext, useReducer } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import FileSubmission from '../FileSubmission/FileSubmission';
import Organization from '../Organization/Organization';
import './Home.css';
import Header from '../Header/Header';
import Status from '../Status/Status';
import Footer from '../Footer/Footer';

const initialState = { orgs: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ORGS':
      return { orgs: action.payload };
    case 'ADD_ORGS':
      return { orgs: [...state.orgs, action.payload] };
    default:
      throw new Error('Action not found');
  }
}

const Home = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { orgs } = state;
  const { isAdmin, isSuperAdmin } = useContext(AuthContext);
  return (
    <div className="wrap">
      <div className="app">
      <Header />
      <Tabs defaultActiveKey="file_submission" id="main-file-submission-menu">
        <Tab eventKey="file_submission" title="File Submission">
          <div>
            <FileSubmission orgs={orgs} dispatch={dispatch} />
          </div>
        </Tab>
        <Tab eventKey="status" title="Status">
          <div>
            <Status orgs={orgs} dispatch={dispatch} />
          </div>
        </Tab>
        {isAdmin || isSuperAdmin ? (
          <Tab eventKey="organization" title="Manage Organizations">
            <div>
              <Organization />
            </div>
          </Tab>
        ) : null}
      </Tabs>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
