import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import LoaderButton from '../LoaderButton/LoaderButton';
import { useSelector } from 'react-redux';
import axios from 'axios';

const DeleteOrgModal = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const idToken = useSelector((state) => state.tokens.idToken);

  const handleDeleteOrg = async (e) => {
    setIsDeleting(true);
    e.preventDefault();

    try {
      const organizations_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/organization?orgName=${props.orgToDelete}`;
      const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
      await axios.delete(organizations_url, options)
      .then((res) => {

      })
      .catch((err) => {
        console.error(err);
      });

    } catch (err) {
      console.error(err, err.stack);
    }
    props.closeModal();
    setIsDeleting(false);
    props.handleRefresh();
  };

  const handleCancel = () => {
    setIsCancelling(true);
    props.closeModal();
    setIsCancelling(false);
  };
  return (
    <div>
      <Modal
        show={props.showDeleteOrgModal}
        onHide={props.closeModal}
        dialogClassName="modal-40w"
        aria-labelledby="Delete Organization Modal"
      >
        <Modal.Body className="text-center">
          <>
            <h5>
              Are you sure you want to delete
              <br />
              {props.orgNameToDelete}?
            </h5>
            <LoaderButton
              type="submit"
              isLoading={isDeleting}
              text="Yes"
              loadingText=" Deleting org..."
              onClick={handleDeleteOrg}
            />{' '}
            <LoaderButton
              type="submit"
              isLoading={isCancelling}
              text="Cancel"
              loadingText=" Cancelling org delete..."
              onClick={handleCancel}
            />
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteOrgModal;
