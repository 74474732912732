import React from 'react';
import pkg from '../../../package.json';
import './Footer.css';

const Footer = () => {
  const version = pkg.version;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  return (
    <div className="app-footer">
          Filesubmission | {environment} v{version}
    </div>
  );
};

export default Footer;
