const fileObjects = (
  state = {
    fileObjects: [],
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_FILE_PROGRESS':
      return {
        ...state,
        fileObjects: state.fileObjects.filter((obj, index) => {
          if (index === action.payload.index) {
            obj.fileProgress = action.payload.fileProgress;
          }
          return obj;
        }),
      };

    default:
      return state;
  }
};

export default fileObjects;
