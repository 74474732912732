import { combineReducers } from 'redux';
import fileObjects from './fileObjects';
import tokens from './tokens';

const rootReducer = combineReducers({
  fileObjects,
  tokens
});

export default rootReducer;
