import React, {useContext, useEffect, useReducer, useRef, useState} from 'react';
import axios from 'axios';
import bsCustomFileInput from 'bs-custom-file-input';
import moment from 'moment-timezone';
import { Alert, Col, Form, ProgressBar, Row } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import LoaderButton from '../LoaderButton/LoaderButton';
import useOrgs from '../../custom-hooks/useOrgs';
import createFileSub from '../../helpers/createFileSub';
import uploadFile from '../../helpers/uploadFile';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import './FileSubmission.css';
import { useSelector } from 'react-redux';
import Switch from '../Switch/Switch';

const initialState = { orgs: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'SET':
      return { orgs: action.payload };
    case 'ADD':
      return { orgs: [...state.orgs, action.payload] };
    default:
      throw new Error('Action not found!');
  }
}

const FileSubmission = () => {
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  const {user, extUser, isAdmin, isSuperAdmin} = useContext(AuthContext);
  const isMountedRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [organizations] = useOrgs();
  const [attFile, setAttFile] = useState(null);
  const [deliveryFile, setDeliveryFile] = useState(null);
  const [attFileName, setAttFileName] = useState('');
  const [deliveryFileName, setDeliveryFileName] = useState('');
  const [orgType, setOrgType] = useState('');
  const [specificationType, setSpecificationType] = useState('');
  const [orgName, setOrgName] = useState('');
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAttError, ] = useState(false);
  const [showDeliveryError, ] = useState(false);
  const [uploadErrorMessage, ] = useState('');
  const [validate, setValidate] = useState(false);
  const [popName, setPopName] = useState('');
  const [attProgress, setAttProgress] = useState(0);
  const [delProgress, setDelProgress] = useState(0);
  const [independentFileLoad, setIndependentFileLoad] = useState(false);
  const [expireACRS, setExpireACRS] = useState(false);
  const [expirationDays, setExpirationDays] = useState('90');
  const [orgNameError, setOrgNameError] = useState(false);
  const [stepFunctionTriggered, setStepFunctionTriggered] = useState(false);
  const [lastSubmittedOrgName, setlastSubmittedOrgName] = useState('');
  const idToken = useSelector((state) => state.tokens.idToken);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [showSkipAttribution, setShowSkipAttribution] = useState(false);
  const [skipAttribution, setSkipAttribution] = useState(false);

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const confirmModal = () => {
    CreateFileSubmission();
  };

  const confirmOrSubmit = () => {
    if ((independentFileLoad) && (!attFile || !deliveryFile)) {
      const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
      const supportLink = "mailto:" + supportEmail;
      const msg = () => {
        return (
          <p>
          <span>You have not submitted both an attribution and delivery files. Please confirm that you will only be submitting one of the two files. If you are unsure, please reach out to our helpdesk at </span>
          <a href={supportLink}>{supportEmail}</a> 
          <span> for assistance.</span>
          </p>
        )
      } 
      setConfirmModalMessage(msg);
      setShowConfirmModal(true);
    } else {
      CreateFileSubmission();
    }
  }

  useEffect(() => {
    if ((isAdmin || isSuperAdmin) 
      && (process.env.REACT_APP_FILESUBMISSION_DOMAIN.includes("mihin.cloud") || process.env.REACT_APP_FILESUBMISSION_DOMAIN.includes("njhin.services"))
    ) {
      setShowSkipAttribution(true);
    }
  }, [isAdmin, isSuperAdmin, showSkipAttribution]);

  useEffect(() => {
    isMountedRef.current = true;
    bsCustomFileInput.init();
    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    extUser.attributes['custom:org_name'].toLowerCase() === 'empty' && setOrgNameError(true);
    return () => (isMountedRef.current = false);
  }, [extUser.attributes]);

  useEffect(() => {
    isMountedRef.current = true;
    if (extUser) {
      if (extUser.attributes['custom:org_name'].includes(',')) {
        dispatch({type: 'SET', payload: extUser.attributes['custom:org_name'].split(', ').sort()});
      } else if (extUser.attributes['custom:org_name'].toLowerCase() !== 'all') {
        setOrgName(extUser.attributes['custom:org_name']);
      }
      setUserName(extUser.attributes.email);
    }
    if (user) {
      if (user.attributes['custom:org_name'].includes(',')) {
        dispatch({type: 'SET', payload: extUser.attributes['custom:org_name'].split(', ').sort()});
      } else if (extUser.attributes['custom:org_name'].toLowerCase() !== 'all') {
        dispatch({type: 'SET', payload: extUser.attributes['custom:org_name']});
      }
      setUserName(user.attributes.email);
    }

    return () => (isMountedRef.current = false);
  }, [user, extUser, organizations]);

  useEffect(() => {
    isMountedRef.current = true;
    if (orgName !== '' && orgName !== 'All' && orgName !== 'all') {
      const newOrgs = filterOrgs(organizations, orgName);
      const orgPopArray = newOrgs.map((org) => org.orgPopulation);
      const orgPopString = orgPopArray.join();
      setPopName(orgPopString);
      const orgTypeArray = newOrgs.map((org) => org.orgType);
      const orgTypeString = orgTypeArray.join();
      setOrgType(orgTypeString);
      const specTypeArray = newOrgs.map((org) => org.specificationType);
      const specTypeString = specTypeArray.join();
      setSpecificationType(specTypeString);

      const indepdentLoadArray = newOrgs.map((org) => org.independentFileLoad);
      let indepdentLoadBool = indepdentLoadArray.join() === "true";
      setIndependentFileLoad(indepdentLoadBool);

      const expireACRSArray = newOrgs.map((org) => org.expireACRS);
      const expireACRSString = expireACRSArray.join();
      setExpireACRS(expireACRSString);
      const expirationDaysArray = newOrgs.map((org) => org.expirationDays);
      const expirationDaysString = expirationDaysArray.join();
      setExpirationDays(parseInt(expirationDaysString));
    }
    return () => (isMountedRef.current = false);
  }, [orgName, organizations, state.orgs]);

  useEffect(() => {
    isMountedRef.current = true;
    if (attProgress === 100 && delProgress === 100 && stepFunctionTriggered && !showError) {
      setAttProgress(0);
      setDelProgress(0);
      setStepFunctionTriggered(false);
      document.getElementById("filesubmissionSubmitForm").reset();
      setShowSuccess(true);
    }
    else if (attProgress === 100 && delProgress === 100 && showError) {
      setAttProgress(0);
      setDelProgress(0);
      setStepFunctionTriggered(false);
      document.getElementById("filesubmissionSubmitForm").reset();
    }
    return () => (isMountedRef.current = false);
  }, [attProgress, delProgress, stepFunctionTriggered, showError]);

  function filterOrgs(arr, searchKey) {
    return arr.filter((obj) => obj.orgName.toUpperCase() === searchKey.toUpperCase());
  }

  const CreateFileSubmission = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsLoading(true);
    closeAlert();

    if ((attFile || deliveryFile)) {
      // get signed urls for s3 upload
      let attFilePathUrl = '';
      let deliveryFilePathUrl = '';
      let attFilePath = ''
      let deliveryFilePath = ''
      const auth_s3_url = `https://${process.env.REACT_APP_FILESUBMISSION_DOMAIN}/auth/s3/upload/url`;
      const options = {headers: { 'Authorization': 'Bearer ' + idToken }};
      const frontSubmissionTime = moment().tz('America/Detroit').format('YYYY-MM-DD-HH:mm:ss');
      let transactionId = '';
      let submissionTime = '';
      const auth_s3_body = {'datasource': orgName, 'attribution_file_name': attFileName, 'delivery_file_name': deliveryFileName};
      setlastSubmittedOrgName(orgName);

      // Get Signed Upload URLs
      let retrieveUrlSucces = true;
      await axios.post(auth_s3_url, auth_s3_body, options)
      .then((res) => {
        let response = res.data;
        attFilePath = response.attribution_raw_url;
        attFilePathUrl = response.attribution_signed_url;
        deliveryFilePath = response.delivery_raw_url;
        deliveryFilePathUrl = response.delivery_signed_url;
        transactionId = response.transaction_id;
        submissionTime = response.submission_time;
      })
      .catch((err) => {
        console.error(err);
        retrieveUrlSucces= false;
      });
      if (!retrieveUrlSucces) {
        document.getElementById("filesubmissionSubmitForm").reset();
        setDeliveryFile(null);
        setAttFile(null);
        setDescription('');
        setOrgName('');
        setIsLoading(false);
        setValidate(false);
        setShowError(true);
        setErrorMessage(`There was an error with your upload for ${orgName}. Please try again. If the problem persists please contact ${supportEmail}`);
        return;
      }

      const fileUploads = [];
      // Upload Attribution file
      if (attFile && attFilePath) {
        fileUploads.push(uploadFile(attFile, attFilePathUrl, setAttProgress));
      }

      // Upload Delivery file
      if (deliveryFile && deliveryFilePath) {
        fileUploads.push(uploadFile(deliveryFile, deliveryFilePathUrl, setDelProgress));
      }

      // Await file uploads to complete
      for (const f of fileUploads) {
        await f;
      }
      console.log('Finished upload files!');


      await createFileSub(
        popName,
        transactionId,
        (attFile) ? attFileName : null,
        (attFile) ? attFilePath : null,
        (deliveryFile) ? deliveryFileName : null,
        (deliveryFile) ? deliveryFilePath : null,
        orgName,
        orgType,
        specificationType,
        userName,
        description,
        validate,
        setShowError,
        setErrorMessage,
        expireACRS,
        expirationDays,
        submissionTime,
        frontSubmissionTime,
        idToken,
        skipAttribution
      );
      setAttProgress(100);
      setDelProgress(100);
      setStepFunctionTriggered(true);
    }
    setDeliveryFile(null);
    setAttFile(null);
    setDescription('');
    setOrgName('');
    setIsLoading(false);
    setValidate(false);
    setSkipAttribution(false);
  };

  const closeAlert = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  const attOnChange = (e) => {
    if (e.target.files[0] && e.target.files[0].size && e.target.files[0].size > 0) {
      setAttFile(e.target.files[0]);
      setAttFileName(e.target.files[0].name.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1-' + Date.now() + '$2'));
    } else {
      setAttFile(null);
    }
  };

  const deliveryOnChange = (e) => {
    if (e.target.files[0] && e.target.files[0].size  && e.target.files[0].size > 0) {
      setDeliveryFile(e.target.files[0]);
      setDeliveryFileName(e.target.files[0].name.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1-' + Date.now() + '$2'));
    } else {
      setDeliveryFile(null);
    }
  };

  const validateForm = () => {
    let valid = false;
    if (orgName && (independentFileLoad) && (attFile || deliveryFile)) {
      valid =  true;
    } else if (orgName && attFile && deliveryFile) {
      valid =  true;
    }
    return valid;
  };

  return (
    <>
      {orgNameError ? (
        <div className="orgNameError">
          <h3>Organization Name Not Set</h3>
          <p>
            There is no organization name set on your profile. Please contact
            your administrator to fix this issue.
          </p>
        </div>
      ) : (
        <>
          <div className="FileSubmission button-container">
            <form id="filesubmissionSubmitForm" onSubmit={CreateFileSubmission}>
              <Form.Group controlId="description">
                <Form.Label>Description of Files:</Form.Label>
                <Form.Control
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              {organizations.length > 1 && (
                <Form.Group controlId="orgName">
                  <Form.Label>Choose Organization:</Form.Label>

                  <Form.Control
                    as="select"
                    value={orgName}
                    onChange={(e) => {
                      setOrgName(e.target.value);
                    }}
                  >
                    <option>Choose...</option>
                    {organizations.map((org) => {
                      return <option key={`orgs-${org.orgName}`}>{org.orgName}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              )}
              {/* Delivery File */}
              <div className="form-label">
                <label>
                  Please select your <strong>Delivery</strong> file:
                </label>
              </div>
              <div className="custom-file">
                <input
                  id="DeliveryFileInput"
                  type="file"
                  accept="text/csv,text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  className="custom-file-input"
                  onChange={deliveryOnChange}
                />
                <label className="custom-file-label" htmlFor="DeliveryFileInput">
                  Choose file
                </label>
              </div>
              {showDeliveryError && <p>{uploadErrorMessage}</p>}
              {delProgress > 0.1 && (
                <ProgressBar
                  now={delProgress}
                  label={`${Math.floor(delProgress)}%`}
                />
              )}
              <hr />
              {/* Attribution File */}
              <div className="form-label">
                <label>
                  Please select your <strong>Attribution</strong> file:
                </label>
              </div>

              <div className="custom-file">
                <input
                  id="AttributionFileInput"
                  type="file"
                  accept="text/csv,text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  className="custom-file-input"
                  onChange={attOnChange}
                />
                <label className="custom-file-label" htmlFor="AttributionFileInput">
                  Choose file
                </label>
              </div>
              {showAttError && <p>{uploadErrorMessage}</p>}
              {attProgress > 0.1 && (
                <ProgressBar
                  now={attProgress}
                  label={`${Math.floor(attProgress)}%`}
                />
              )}
              <hr />

              {showSkipAttribution && (
                <Form.Group controlId="skipAttribution">
                  <Row>
                    <Col>
                      <Form.Label>Skip Attribution</Form.Label>
                    </Col>
                    <Col>
                      <Switch
                        isOn={skipAttribution}
                        onColor="#06D6A0"
                        handleToggle={() => setSkipAttribution(!skipAttribution)}
                        switchId="skipAttribution"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              )}

              <Form.Group controlId="validate">
                <Row>
                  <Col>
                    <Form.Label>Validate only?</Form.Label>
                  </Col>
                  <Col>
                    <Switch
                      isOn={validate}
                      onColor="#06D6A0"
                      handleToggle={() => setValidate(!validate)}
                      switchId="validate"
                    />
                  </Col>
                </Row>
              </Form.Group>

              <LoaderButton
                block
                type="button"
                onClick={() => confirmOrSubmit()}
                disabled={!validateForm()}
                isLoading={isLoading}
                className='FSSubmit'
                text="Submit"
                loadingText="Submitting file..."
              />

            </form>
          </div>
          <div className="response">
            {showSuccess && (
              <Alert variant="success">
                Your files have been successfully uploaded for {lastSubmittedOrgName}!
              </Alert>
            )}
            {showError && <Alert variant="danger">{errorMessage}</Alert>}
          </div>
        </>
      )}
        <ConfirmModal
          showConfirmModal={showConfirmModal}
          closeConfirmModal={closeConfirmModal}
          confirmModal={confirmModal}
          message={confirmModalMessage}
        />
    </>

  );
};

export default FileSubmission;
